import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { formatUSD } from '@shared/lib/numbers';
import { Body } from '@src/components/styled/Typography';
import { ReactComponent as FeeBadgeIcon } from '@icons/custom/bid-light.svg';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import { IsDefined } from '@utils/js-ts';
const Price = styled(Body, { shouldForwardProp: (propName) => propName !== 'highlight' })((props) => (Object.assign({}, props.highlight && ({
    fontSize: '15px', // TODO: move font-size numbers into the theme config
    color: props.theme.palette.success.dark,
}))));
const SubscriptionPrice = ({ price = undefined, priceColor = undefined, }) => {
    const { t } = useTranslation();
    const priceDisplay = useMemo(() => {
        let str = t('portfolio.no_fee');
        if (IsDefined(price)) {
            // TODO: get currency sign based on the currency that the fee was created in
            str = formatUSD(price, { compactNotation: true });
        }
        return str;
    }, [price, t]);
    return (_jsx(IconText, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: FeeBadgeIcon })), children: _jsx(Price, { color: priceColor, highlight: false, children: priceDisplay }) }));
};
export default SubscriptionPrice;
