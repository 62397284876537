import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { formatNumber } from '@shared/lib/numbers';
import { ReactComponent as FollowersIcon } from '@icons/wolfkit-light/users-light.svg';
import { Body } from '@src/components/styled/Typography';
import { ContainerRow } from '@src/components/styled';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import { IsDefined } from '@utils/js-ts';
const IconTextStyled = styled(IconText)(() => ({
    gap: 4,
}));
const Container = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
}));
const Subscribers = ({ followersCount, userQueuePosition = undefined, }) => {
    const theme = useTheme();
    const followersCountFormatted = useMemo(() => formatNumber(followersCount, { compactNotation: true, maximumFractionDigits: 0 }), [followersCount]);
    return (_jsxs(IconTextStyled, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: FollowersIcon })), children: [!IsDefined(userQueuePosition) && (_jsx(Body, { children: followersCountFormatted })), IsDefined(userQueuePosition) && (_jsxs(Container, { children: [_jsx(Body, { color: theme.palette.text.primary, children: userQueuePosition }), followersCount > 1 && (_jsx(Body, { whiteSpace: 'pre', children: ` /${followersCountFormatted}` }))] }))] }));
};
export default Subscribers;
